import 'antd/dist/antd.css'; 
import React, { useEffect, useState, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

import { Layout, Modal, Input, Menu, Breadcrumb, notification, Button, DatePicker, Steps, message, Radio, Spin, Result, Space, Typography } from 'antd';
import io from 'socket.io-client';
import moment from 'moment';
import bcrypt from 'bcrypt-nodejs'

const { Paragraph } = Typography;
const { Header, Content, Footer } = Layout;
const { RangePicker } = DatePicker;
const { Step } = Steps;

const steps = [
  {
    title: 'Select type export',
  },
  {
    title: 'Pick range date',
  },
  {
    title: 'Processing',
  },
  {
    title: 'Final',
  },
];

function App() {

  

  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`
    // , {
    //   path: "/npv-multi-export-socket/socket.io/"
    // }
    );

    setSocket(newSocket);

    const messageListener = (res) => {
      console.log(res)
      if(res.type.indexOf("SERVER_REPORT_STATUS") > -1){
        notification['info']({
          placement: 'bottomRight',
          message: 'GET_REPORT_STATUS',
          description: res.msg,
        });
      }
      if(res.type.indexOf("SERVER_REPORT_PROCESSING") > -1){
        notification['info']({
          placement: 'bottomRight',
          message: 'SERVER_REPORT_PROCESSING',
          description: res.msg,
        });
      }
      if(res.type.indexOf("SERVER_REPORT_ERROR") > -1){
        notification['error']({
          placement: 'bottomRight',
          message: 'SERVER_REPORT_ERROR',
          description: res.msg,
        });
        setErrorSignText(res.data)
        setErrorSign(1)
      }
      if(res.type.indexOf("SERVER_REPORT_DONE") > -1){
        notification['success']({
          placement: 'bottomRight',
          message: 'SERVER_REPORT_DONE',
          description: res.msg,
        });

        clearInterval(increment.current)

        setFileDownload(res.url)

        setCurrent(3)
      }
      if(res.type.indexOf("SERVER_MESSAGE_SYSTEM") > -1){
        notification['info']({
          placement: 'bottomRight',
          message: 'SERVER_MESSAGE_SYSTEM',
          description: res.msg,
        });
      }
      // window.alert(res.msg)
    };

    
    newSocket.on('message', messageListener);

    newSocket.emit("action", {
      type: "GET_REPORT_STATUS"
    })

    return () => newSocket.close();
  }, [setSocket]);

  const [datePick, setDatePick] = useState(null);
  useEffect(() => {
    console.log(datePick)
  }, [datePick]); // Only re-run the effect if count changes

  const [fileDownload, setFileDownload] = React.useState("");

  const [current, setCurrent] = React.useState(0);
  const next = () => {
    if(current == 1) {
      if(datePick) {
        let bPass = true
        if(valueRadio == 8){
          let diff = datePick[1].diff(datePick[0], 'days', true)
          if(diff > 4){
            bPass = false
            notification['error']({
              placement: 'bottomRight',
              message: 'ERROR',
              description: "Không được chọn độ dài lớn hơn 4 ngày cho loại báo cáo này",
            });
          }
        }else{
          let diff = datePick[1].diff(datePick[0], 'months', true)
          if(diff > 3){
            bPass = false
            notification['error']({
              placement: 'bottomRight',
              message: 'ERROR',
              description: "Không được chọn độ dài lớn hơn 3 tháng cho loại báo cáo này",
            });
          }
        }

        if(bPass){
          setCurrent(current + 1);
          testButton()
        }
        

      }else{
        notification['error']({
          placement: 'bottomRight',
          message: 'ERROR',
          description: "Vui lòng chọn khoảng thời gian muốn tạo báo cáo",
        });
      }
    }else{
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };


  const testButton = () => {

    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)

    let date01,date02,date03,date04 = null;
    
    if(valueRadio == 1 || valueRadio == 4 || valueRadio == 8 || valueRadio == 11 || valueRadio == 13) {
      date01 = datePick[0].format("YYYY-MM-DD")
      date02 = datePick[1].format("YYYY-MM-DD")
    } else if (valueRadio == 10) {
      date01 = datePick[0].format("YYYY")
      date02 = datePick[1].format("YYYY")
    } else {
      date01 = datePick[0].format("YYYY-MM")
      date02 = datePick[1].format("YYYY-MM")
    }

    socket.emit("action",{
        type: "RUN_REPORT",
        report_number: valueRadio,
        "date01": date01,
        "date02": date02,
        "date03": date03,
        "date04": date04,
    })
  }

  const downloadFile = () => {
    fetch(`${process.env.REACT_APP_API_URL}/v1/download?filename=${fileDownload}`).then((response) => {
        console.log(response.headers.get("content-disposition"));
        let fileName = response.headers
        .get("content-disposition")
        .split('filename="')[1]
        .split('"')[0];

        response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        });
      }
    );
  }

  const [valueRadio, setValueRadio] = React.useState(1);
  const onChangeRadio = e => {
    setValueRadio(e.target.value);
    console.log('radio checked', valueRadio);
  };

  const increment = useRef(null)
  const [timer, setTimer] = useState(1)
  const formatTime = () => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }

  const [errorSign, setErrorSign] = useState(0)
  const [errorSignText, setErrorSignText] = useState("")
  const [passAuthentication, setPassAuthentication] = useState(false)
  const [loginpassword, setLoginpassword] = useState(false)
  const checkLoginPassword = async () => {
    let check = await bcrypt.compareSync( loginpassword, "$2a$10$dkuwbVo7i6Kw9tW/KU2CtOwocPvIUstL3vZfUFNFIKU9tT3KaKlgi")
    if(check){
        localStorage.setItem("bsq",JSON.stringify({
            "date_login": moment().format()
        }))
        setPassAuthentication(true)
    }
  }
  const onChangePassInput = e => {
    setLoginpassword(e.target.value);
  };

  useEffect(() => {
    if(localStorage.bsq == undefined){
      // setPassAuthentication(false)
    }else{
      let date_login = JSON.parse(localStorage.bsq).date_login
      date_login = moment(date_login).add(1,"days")
      let current = moment()
  
      if(current.isAfter(date_login)){ //Expires
        localStorage.clear();
        // setPassAuthentication(false)
      }else{
        setPassAuthentication(true)
      }

    }
  })

  return (
    <Layout className="layout" style={{minHeight:"100vh"}}>
      <Header>
        <div className="logo" />
        <h2 style={{color:"white"}}>NPV MULTIPLE EXPORTS TOOL</h2>
        {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key={1}>Sale</Menu.Item>
          
        </Menu> */}
      </Header>
      <Content style={{ padding: '20px 50px', width:'900px', margin:"auto" }}>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="site-layout-content" style={{ background : "white", padding: "40px" , minHeight: '500px'}}>

        {passAuthentication == false ? 
            <div style={{position:"fixed", left:0, top:0, right:0, bottom:0, backgroundColor:"#f3f3f3", zIndex:999}} >
              <Modal
                  title="Sign-in with password"
                  visible={true}
                  closable={false}
                  footer={[
                    <Button key="submit" type="primary" onClick={checkLoginPassword}>
                      Submit
                    </Button>,
                  ]}
                >
                  <Input.Password placeholder="input password" onChange={onChangePassInput} onPressEnter={checkLoginPassword} />
                </Modal>
            </div>
        :
        <>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content" style={{minHeight:"400px", padding:"40px"}}>
            {current == 0 && (
              <div>
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                  <Space direction="vertical">
                    <Radio value={1}>MNP SCANNING REPORT-DAILY</Radio>
                    <Radio value={2}>MNP SCANNING REPORT-MONTHLY</Radio>
                    <Radio value={3}>MNP CUSTOMGROUP REPORT</Radio>
                    <Radio value={4}>MDS VISITING REPORT-DAILY</Radio>
                    <Radio value={5}>MDS VISITING REPORT-MONTHLY</Radio>
                    <Radio value={6}>DDD DPOINT REPORT MONTHLY</Radio>
                    <Radio value={7}>DDD CUSTOMGROUP REPORT</Radio>
                    <Radio value={8}>MNP SCANOUT REPORT</Radio>

                    <Radio value={9}>SALE PAYMENT MONTHLY REPORT</Radio>
                    <Radio value={10}>SALE PAYMENT YEARLY REPORT</Radio>

                    <Radio value={11}>MFF VISITING DAILY REPORT</Radio>
                    <Radio value={12}>MFF VISITING MONTHLY REPORT</Radio>

                    <Radio value={13}>PST VISITING DAILY REPORT</Radio>
                    <Radio value={14}>PST VISITING MONTHLY REPORT</Radio>
                    
                  </Space>
                </Radio.Group>
              </div>
            )}
            {current == 1 && (valueRadio == 1 || valueRadio == 4 || valueRadio == 8 || valueRadio == 11 || valueRadio == 13) && (
              <>
                <RangePicker ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}  
                onChange={value => setDatePick(value)} />
              </>
            )}
            {current == 1 && (valueRadio == 2 || valueRadio == 3 || valueRadio == 5|| valueRadio == 6 || valueRadio == 7 || valueRadio == 9 || valueRadio == 12 || valueRadio == 14) && (
              <>
                <RangePicker ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}  
                picker="month" onChange={value => setDatePick(value)} />
              </>
            )}
            {current == 1 && (valueRadio == 10) && (
              <>
                <RangePicker ranges={{
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}  
                picker="year" onChange={value => setDatePick(value)} />
              </>
            )}
            {current == 2 && errorSign == 0 && (
              <Result
                title="Your operation has been executed"
                extra={
                  <>
                    <Spin size="large" />
                    <Paragraph>{formatTime()}</Paragraph>
                  </>
                  
                }
              />
            )}
            {current == 2 && errorSign == 1 &&(
              <Result
                status="warning"
                title="There are some problems with your operation."
                extra={
                  <>
                    <div> { ReactHtmlParser(errorSignText) } </div>
                    <br />
                    <Button key="reload" onClick={()=>{ window.location.href = ''}}>Make another report</Button>
                  </>
                }
              />
            )}
            {current == 3 && (
              <>
                <Result
                  status="success"
                  title="Successfully Report Exported"
                  subTitle={fileDownload}
                  extra={[
                    <Button type="primary" key="download" onClick={()=>downloadFile()}>
                      Download file
                    </Button>,
                    <Button key="reload" onClick={()=>{ window.location.href = ''}}>Make another report</Button>,
                  ]}
                />

              </>
            )}

    
          </div>
          <div className="steps-action" style={{textAlign:"right"}}>
            {current < steps.length - 2 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {current < 2 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </>
        }

         

        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Powered by Lubrytics ©2021</Footer>
    </Layout>
  );
}


export default App;
